import axios from "axios";
import { Moment } from "moment-timezone";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import { ApiError, IdVerificationStatus } from "./constants";

export const fetcher = (
  url: string,
  email: string,
  checkAfter: Moment
): Promise<SWRResponse<UseTours, Error>> =>
  axios.post(url, { email, checkAfter }).then((res) => res.data);

export type TourDetail = {
  communityName: string;
  startTime?: Moment;
  status?: string;
  waiverSigned?: boolean;
  leasingEmailAddress?: string;
  leasingPhoneNumber?: string;
  leasingAgentName?: string;
};

export type UserInfo = {
  firstName: string;
  lastName?: string;
};

export type UseToursResult = {
  hasTours: boolean;
  user?: UserInfo;
  idVerifyDateTime?: Moment;
  idVerifyStatus?: IdVerificationStatus;
  nextScheduledTour?: TourDetail;
};

export type UseTours = {
  isValidating: boolean;
  data?: UseToursResult;
  error?: ApiError;
};

export const DefaultOptions: SWRConfiguration = {
  refreshInterval: 15000
};

export const useTours = (
  email: string,
  checkAfter: Moment,
  options?: SWRConfiguration
): UseTours => {
  const { data, error, isValidating } = useSWR(
    ["/api/tours", email, checkAfter],
    fetcher,
    options || DefaultOptions
  );
  return { isValidating, data, error };
};

export default useTours;
