import React from "react";

import { useTours } from "@utils/useTours";
import { useAppSelector } from "@redux/hooks";
import { Card, Container } from "react-bootstrap";
import { Header, Main, Footer, Wizard } from "@components";

const Home: React.FC = () => {
  const state = useAppSelector((state) => state.status);
  const { data, error, isValidating } = useTours(state.email, state.lastRetryAttempt);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header showLogout={state.emailIsValid} />
      <Main />
      {error ? (
        <Container className="my-5 flex-grow-1">
          <Card className="border-none">
            <Card.Header className="h3">Error</Card.Header>
            <Card.Body>
              <Card.Title>{error.message}</Card.Title>
            </Card.Body>
          </Card>
        </Container>
      ) : (
        <Wizard state={state} tourData={data} tourDataValidating={isValidating} />
      )}
      <Footer />
    </div>
  );
};

export default Home;
